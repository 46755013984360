<template>
  <div class="wh-full-height-box wh-space-bottom">
    <b-card>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </b-card>

    <b-card>
      <table-header
        ref="header"
        :title="contentFacility ? `${title} - ${contentFacility}` : title"
        :sub-title="subTitle"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="true"
        sub-title-class="text-danger"
      />
      <Editor
        class="editor"
        ref="editor"
        :content="content"
        :isShowPreviewer="isShowPreviewer"
        @input="(v) => content = v"
      />
      <div class="mt-1 d-flex justify-content-between align-items-center">
        <button-permission
          variant="primary"
          @click="submit"
          permission="Kiosk_Menu_About_Us"
          class="mr-1"
        >
          {{ submitBtnText }}
        </button-permission>
        <div class="d-flex align-items-center">
          <div class="mr-1">預覽</div>
          <b-form-checkbox
            :checked="isShowPreviewer"
            class="custom-control-primary"
            name="check-button"
            @input="isShowPreviewer = !isShowPreviewer"
            switch
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import common from '@/common'
import TableSearch from '@/components/TableSearch'
import TableHeader from '@/components/TableHeader'
import ButtonPermission from '@/components/ButtonPermission'

import Editor from '@/components/wangEditor/Index'

export default {
  name: 'KioskManageAboutUs',
  components: {
    TableSearch,
    TableHeader,
    ButtonPermission,
    Editor,
  },
  data() {
    return {
      title: common.getMenuName('kioskManageAboutUs'),
      subTitle: common.getI18n('kiosk.about_us_title_tips'),
      is_admin: common.isAdmin(),
      view_permission: common.checkPermission('Kiosk_Menu_About_Us'),
      // view_permission: false,
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
      },
      header_actions: [
        // {
        //   text: 'PrintContent',
        //   variant: 'primary',
        //   fun: this.printContent,
        //   permission: 'Kiosk_Menu',
        // },
        // {
        //   text: 'GetEditor',
        //   variant: 'primary',
        //   fun: this.getEditor,
        //   permission: 'Kiosk_Menu',
        // },
        // {
        //   text: 'GetToolbar',
        //   variant: 'primary',
        //   fun: this.getToolbar,
        //   permission: 'Kiosk_Menu',
        // },
        // {
        //   text: 'GetHtml',
        //   variant: 'primary',
        //   fun: this.getHtml,
        //   permission: 'Kiosk_Menu',
        // },
      ],
      submitBtnText: this.$t('common.submit'),
      isSubmitting: false,
      isShowPreviewer: false,

      // 編輯器的內容
      content: ``,
      contentDefault: ``,
      contentFetched: ``,

      // 編輯器的備註
      remark: null, // 用於發送到後端
      remarkDefault: {
        editor: 'wangEditor',
      },
      remarkFetched: null,

      contentFacility: '',
    }
  },

  computed: {
    // 發送到後端的內容
    contentFull: {
      get() {
        // return encodeURIComponent(`` +
        return `` +
          `<!doctype html>` +
          `<html lang="en">` +
          `<head>` +
          `<meta charset="UTF-8">` +
          `<meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">` +
          `<meta http-equiv="X-UA-Compatible" content="ie=edge">` +
          `<title>AboutUs</title>` +
          `</head>` +
          `<body>${this.content}</body>` +
          `</html>`

      },
    },
  },

  mounted() {
    common.getSearchCompanyOptions('companyID', this)
    common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
    this.fetchContent()
  },

  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },

  watch: {
    content: {
      handler: function (val, oldVal) {
        // console.log('old content:', oldVal.substring(0, 20) + '...' + oldVal.substring(oldVal.length - 20), oldVal.length)
        // console.log('new content:', val.substring(0, 20) + '...' + val.substring(val.length - 20), val.length)
        if(val.length < 50 && oldVal.length >= 100 && val.startsWith('<p>') && val.includes('<br>')) {
          common.showAlert({
            title: '檢測到當前內容過少，建議再次篩選院舍以重新獲取內容，或直接編輯新內容並提交以覆蓋原內容。',
            text: '如果該提示是在您編輯過程中出現的，請忽略',
          })
        }
      },
    },
  },

  methods: {
    async fetchContent() {
      this.$requestWehealth({
        url: '/kioskmanage/GetAboutUs',//
        method: 'get',
        params: this.getSearchCondition(),
      })
        .then(res => {
          const data = res?.data?.kioskAboutUs ?? null
          if (res?.return_code === 0 && data) {
            try {
              // 打印 data.html 的頭尾符
              console.log('html:', data.html.substring(0, 400) + '...' + data.html.substring(data.html.length - 100))

              const parser = new DOMParser()
              const doc = parser.parseFromString(data.html, 'text/html')
              this.contentFetched = doc.body?.innerHTML ?? doc

              // 打印 this.contentFetched 的頭尾
              console.log('content:', this.contentFetched.substring(0, 100) + '...' + this.contentFetched.substring(this.contentFetched.length - 100))
            } catch (e) {
              console.error(e)
              common.showAlert({
                title: '解析內容出錯，建議再次篩選院舍以重新獲取內容，或直接編輯新內容並提交以覆蓋原內容。'
              })
              this.contentFetched = ''
            }
            try {
              this.remarkFetched = data.remark ? JSON.parse(data.remark) : null
            } catch (e) {
              console.error(e)
              this.remarkFetched = null
            }
            this.content = this.contentFetched ?? this.contentDefault
            this.remark = this.remarkFetched ?? this.remarkDefault
          } else {
            handleFetchError()
          }
        })
        .catch(err => {
          handleFetchError()
        })
        .finally(() => {
          this.refreshContentFacility()
        })
      const handleFetchError = () => {
        this.content = this.contentDefault
        this.remark = this.remarkDefault
        common.showAlert({
          title: '获取內容出錯，請嘗試再次篩選院舍以重新獲取內容，或直接編輯新內容並提交以覆蓋原內容。'
        })
      }
    },

    submit() {
      if (this.isSubmitting) return

      this.isSubmitting = true
      this.$requestWehealth({
        url: '/kioskmanage/UpdateAboutUs',
        method: 'post',
        data: {
          facilityID: this.is_admin ? this.$refs.search.list.facilityID : this.search_default.facilityID,
          html: this.contentFull,
          remark: JSON.stringify(this.remark),
        },
      })
        .then(res => {
          if (res?.return_code === 0) {
            this.contentFetched = this.content
            common.showToast({
              title: this.$t('kiosk.submit_success'),
              variant: 'success',
            })
          }
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },

    // retrieveBodyContentFromHtml(html) {
    //   let content = ''
    //   const parser = new DOMParser()
    //   const doc = parser.parseFromString(html, 'text/html')
    //   content = doc.body?.innerHTML ?? html
    //   return content
    // },

    refreshContentFacility() {
      this.contentFacility = this.search_fields
        .find(f => f.field === 'facilityID').options
        .find(o => o.value === this.$refs.search.search_condition.facilityID)?.text ?? ''
    },

    printContent() {
      console.log(this.contentFull)
    },
    getEditor() {
      const editor = this.$refs.editor.editor
      console.log(editor)
      window.editor = editor
      return editor
    },
    getToolbar() {
      const toolbar = this.$refs.editor.toolbar
      window.toolbar = toolbar
      return toolbar
    },
    getHtml() {
      return this.$refs.editor.getHtml()
    },

    runSearch: function () {
      if (this.content !== this.contentFetched) {
        common.showConfirm({
          title: '內容仍未提交，要切換院舍嗎？',
          confirm_fun: () => {
            this.fetchContent()
            const vmOfSearch = this.$refs.search
            common.syncSearchFields({
              companyID: vmOfSearch.list.companyID,
              facilityID: vmOfSearch.list.facilityID,
              floorID: vmOfSearch.list.floorID,
              roomID: vmOfSearch.list.roomID,
            })
          },
        })
      } else {
        this.fetchContent()
      }
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false,
      })
    },

    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()
      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)
      return where
    },

  },
}
</script>
