export default {
  editor: {
    more: '更多',
    justify: '對齊',
    indent: '縮進',
    image: '圖片',
    video: '視頻',
  },
  common: {
    ok: '確定',
    delete: '刪除',
    enter: '輸入',
  },
  blockQuote: {
    title: '引用',
  },
  codeBlock: {
    title: '代碼塊',
  },
  color: {
    color: '字體顏色',
    bgColor: '背景顏色',
    default: '默認顏色',
    clear: '清除背景顏色',
  },
  divider: {
    title: '分割線',
  },
  emotion: {
    title: '表情',
  },
  fontSize: {
    title: '字號',
    default: '默認字號',
  },
  fontFamily: {
    title: '字體',
    default: '默認字體',
  },
  fullScreen: {
    title: '全屏',
  },
  header: {
    title: '標題',
    text: '正文',
  },
  image: {
    netImage: '網絡圖片',
    delete: '刪除圖片',
    edit: '編輯圖片',
    viewLink: '查看鏈接',
    src: '圖片地址',
    desc: '圖片描述',
    link: '圖片鏈接',
  },
  indent: {
    decrease: '減少縮進',
    increase: '增加縮進',
  },
  justify: {
    left: '靠左對齊',
    right: '靠右對齊',
    center: '居中對齊',
    justify: '兩端對齊',
  },
  lineHeight: {
    title: '行高',
    default: '默認行高',
  },
  link: {
    insert: '插入鏈接',
    text: '鏈接文字',
    url: '鏈接地址',
    unLink: '取消鏈接',
    edit: '編輯鏈接',
    view: '查看鏈接',
  },
  textStyle: {
    bold: '加粗',
    clear: '清除格式',
    code: '代碼',
    italic: '斜體',
    sub: '下標',
    sup: '上標',
    through: '刪除線',
    underline: '下劃線',
  },
  undo: {
    undo: '撤銷',
    redo: '重做',
  },
  todo: {
    todo: '待辦事項',
  },
  listModule: {
    unOrderedList: '無序列表',
    orderedList: '有序列表',
  },
  tableModule: {
    deleteCol: '刪除列',
    deleteRow: '刪除行',
    deleteTable: '刪除表格',
    widthAuto: '自動寬度',
    insertCol: '插入列',
    insertRow: '插入行',
    insertTable: '插入表格',
    header: '表頭',
  },
  videoModule: {
    delete: '刪除',
    uploadVideo: '上傳視頻',
    insertVideo: '插入視頻',
    videoSrc: '視頻源',
    videoSrcPlaceHolder: '視頻文件url或第三方<iframe>',
    videoPoster: '視頻封面',
    videoPosterPlaceHolder: '封面圖片url',
    ok: '確定',
    editSize: '編輯尺寸',
    width: '寬度',
    height: '高度',
  },
  uploadImgModule: {
    uploadImage: '上傳圖片',
    uploadError: '{{fileName}}上傳錯誤',
  },
  highLightModule: {
    selectLang: '語言',
  },
}
