<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
      ref="toolbar"
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <div class="editorWrapper">
      <Editor
        class="editor"
        style="height: 500px; overflow-y: hidden;"
        v-model="contentX"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
      />
      <div
        v-show="isShowPreviewer"
        class="previewer"
        style="height: 500px; overflow-y: auto; border-left: 1px solid #ccc;"
        v-html="content"
      ></div>
    </div>
  </div>
</template>

<script>
import common from '@/common'

import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { i18nAddResources, i18nChangeLanguage } from '@wangeditor/editor'
import zh_HK from '@/libs/i18n/wang-editor/zh_HK'

const vm = {
  name: 'WangEditor',
  components: {
    Editor,
    Toolbar,
  },

  props: {
    content: {
      type: String,
      default: '',
    },
    toolbarConfig: {
      type: Object,
      default: () => ({
        excludeKeys: [
          'fullScreen',
          'group-video',
          'todo',
          'emotion',
        ],
      }),
    },
    editorConfig: {
      type: Object,
      default: () => ({
        placeholder: '',
        scroll: true,
        MENU_CONF: { // https://www.wangeditor.com/v5/menu-config.html
          uploadImage: {
            base64LimitSize: 15 * 1024 * 1024, // Insert base64 characters if less than this value
            maxFileSize: 5 * 1024 * 1024, // 5M
            onError(file, err, res) {
              common.showAlert({
                title: err.message ?? 'upload error',
              })
            },
          },
        },
      }),
    },
    mode: {
      type: String,
      default: 'default', // 'default' or 'simple'
    },
    isShowPreviewer: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    contentX: {
      get() {
        return this.content
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  created() {
    const that = this

    this.destoryEditor()

    const locale = common.getLocale()
    if (locale === 'zh_HK') {
      i18nAddResources('zh_HK', zh_HK)
      i18nChangeLanguage('zh_HK')
    } else if (locale === 'zh_CN') {
      i18nChangeLanguage('zh-CN')
    } else {
      i18nChangeLanguage('en')
    }

  },

  beforeDestroy() {
    this.destoryEditor()
  },

  methods: {
    printContent() {
      console.log(this.contentX)
    },
    getEditor() {
      console.log(this.editor)
      window.editor = this.editor
      return this.editor
    },
    getToolbar() {
      window.toolbar = this.$refs.toolbar
      return this.$refs.toolbar
    },
    getHtml() {
      const html = this.editor.getHtml()
      console.log(html)
      return html
    },

    onCreated(editor) {
      this.editor = Object.seal(editor)
    },
    destoryEditor() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
      this.editor = null
    },
  },
}
export default vm
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>

<style scoped lang="scss">
.editorWrapper {
  display: flex;
  .editor {
    flex: 1 0 50%;
    box-sizing: border-box;
  }
  .previewer {
    flex: 1 0 50%;
    box-sizing: border-box;
    min-height: 500px;
  }
}
</style>
